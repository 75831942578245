<template>
  <div class="app-container park-activity-comp">
    <div class="filter-line">
      <span>
        <el-tooltip
          class="item"
          effect="dark"
          :disabled="parkActivityObj.selectionList.length > 0"
          :content="$t('commons.multipleSelectionOne')"
          placement="top"
        >
          <el-button
            type="primary"
            @click="retryinginvoicingHandler"
            :class="
              parkActivityObj.selectionList.length === 0 &&
              'not-allowed-primary-button'
            "
            >重试开票
          </el-button>
        </el-tooltip>
        <el-button @click="getTableList" icon="el-icon-refresh-right">{{
          this.$t("commons.refresh")
        }}</el-button>
        <!-- <el-button type="primary" @click="batchSendMsg()"
          >发送恢复短信</el-button
        > -->
        <!-- <el-tooltip
          class="item"
          effect="dark"
          :disabled="parkActivityObj.selectionList.length > 0"
          :content="$t('commons.multipleSelectionOne')"
          placement="right"
        >
          <el-button
            type="danger"
            @click="batchDeleteHandler"
            :class="
              parkActivityObj.selectionList.length === 0 && 'not-allowed-button'
            "
            icon="el-icon-delete"
            plain
            >{{ $t("commons.delete") }}
          </el-button>
        </el-tooltip> -->
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        <i class="iconfont icon-loudoutu"></i>
        筛选
      </div>
    </div>
    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
    <div>
      <el-dialog
        :visible.sync="detailDialogShow"
        width="728px"
        :destroy-on-close="true"
        :close-on-click-modal="false"
      >
        <template slot="title">
          <span>{{ dialogTitle }}</span>
        </template>
        <invoiceFailDetail
          v-if="detailDialogShow"
          :dataset="invoiceFailDetail"
          :dataType="dataDialogType"
          @close="detailDialogClose"
        />
      </el-dialog>
    </div>
    <div>
      <el-dialog
        title="开票结果"
        :visible.sync="detailDialogInvoicing"
        width="30%"
        :show-close="false"
        :destroy-on-close="true"
        :close-on-click-modal="false"
      >
        <span>
          <p>
            数电发票开具成功:{{ invoicingResults.successCount }}张,开具失败:{{
              invoicingResults.errCount
            }}张。
          </p>
          <p>
            电子普票:{{
              invoicingResults.busOrderCount
            }}张,需客户自行重新申请开票。
          </p>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="detailDialogInvoicing = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import invoiceFailDetail from "./invoiceFailDetail.vue";
import { dateFormat } from "@/filters/index";
import {
  findInvoicingFailure,
  deleteInvoice,
  retryingInvoicing,
} from "@/api/ruge/jwPark/invoicingFailure";

export default {
  components: {
    finalTable,
    invoiceFailDetail,
  },
  data() {
    return {
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        // selection: {
        //   need: false,
        //   width: "18",
        // },
        selection: {
          need: true,
          prop: "productId",
          width: "18",
        },
        header: [
          { prop: "createTime", label: "申请时间", width: "" },
          { prop: "billNo", label: "开票记录单号", width: "" },
          { prop: "totalAmount", label: "开票金额", width: "" },
          { prop: "openInvoiceType", label: "抬头类型", width: "" },
          { prop: "buyerName", label: "发票抬头", width: "" },
          { prop: "buyerMobilePhone", label: "手机号码", width: "" },
          { prop: "sendMessage", label: "提醒状态", width: "" },
          { prop: "description", label: "失败原因", width: "" },
          { prop: "drawerType", label: "发票类型", width: "" },
          { prop: "operation", label: "操作", width: "160" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          billNo: {
            type: "input",
            label: "开票记录单号",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入开票记录单号",
            prefixIcon: "el-icon-search",
          },
          totalAmount: {
            type: "numberInput",
            label: "开票金额",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入开票金额",
            prefixIcon: "el-icon-search",
          },
          buyerName: {
            type: "input",
            label: "发票抬头",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入发票抬头",
            prefixIcon: "el-icon-search",
          },
          buyerMobilePhone: {
            type: "input",
            label: "手机号码",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入手机号码",
            prefixIcon: "el-icon-search",
          },
          sendMessage: {
            type: "selector",
            label: "提醒状态",
            value: "",
            placeholder: "请选择提醒状态",
            actionType: "goSearch",
            optionList: [
              {
                value: "0",
                label: "未发送短信提醒",
              },
              {
                value: "1",
                label: "已发送短信提醒",
              },
            ],
          },
          drawerType: {
            type: "selector",
            label: "发票类型",
            value: "",
            placeholder: "",
            actionType: "goSearch",
            optionList: [
              {
                value: "true",
                label: "全电",
              },
              {
                value: "false",
                label: "税盘",
              },
            ],
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          createTime: {
            type: "dateFormat",
          },
          pubdate: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "查看",
              },

              {
                actionType: "iconClick",
                eventName: "sending",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "发送恢复短信",
                hideProp: "flag",
                hideValue: false,
              },

              // {
              //   actionType: "iconClick",
              //   eventName: "delete",
              //   fontSize: "14px",
              //   color: "#1A4CEC",
              //   cursorPointer: "pointer",
              //   iconName: "el-icon-edit",
              //   tooltips: "删除",
              // },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          createTime: {
            type: "dateRange",
            pickerType: "date",
            label: "申请时间",
            value: [],
          },
          billNo: {
            inline: true,
            value: "",
          },
          totalAmount: {
            inline: true,
            value: "",
          },
          buyerName: {
            inline: true,
            value: "",
          },
          buyerMobilePhone: {
            inline: true,
            value: "",
          },
          sendMessage: {
            inline: true,
            value: "",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      parkActivityObj: {
        selectionList: [],
        dialogVisible: false,
        listQuery: {
          current: 1,
          rowCount: 10,
          noticeTitle: null,
          noticeType: null,
          startDate: null,
          endDate: null,
        },
      },
      dialogTitle: "",
      activityTypeList: [],
      loadingFlag: false,
      detailDialogShow: false,
      invoiceFailDetail: [],
      dialogType: "",
      dataDialogType: "",
      detailDialogInvoicing: false,
      invoicingResults: "",
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    this.dataInit();
  },
  methods: {
    tableEventHandler(datas) {
      console.log("datas", datas);
      if (datas.type === "goSearch") {
        this.dealTime(datas);
        this.parkActivityObj.listQuery = {
          ...this.parkActivityObj.listQuery,
          ...datas.params,
        };
        this.parkActivityObj.listQuery.current = 1;
        this.dataset.pageVO.current = 1;
        this.getTableList();
      } else if (datas.type === "paginationChange") {
        this.parkActivityObj.listQuery.current = datas.params.current.page;
        this.parkActivityObj.listQuery.rowCount = datas.params.current.limit;
        this.getTableList();
      } else if (datas.type === "updateSelectionList") {
        //批量发送短信
        this.parkActivityObj.selectionList = datas.list.filter((item) => item);
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            this.keepDetailDialog(datas.row); //查看
            break;
          case "sending":
            this.openDetailDialog(datas.row); //发送短信
            break;
          case "delete":
            this.singleDeleteHandler(datas.row); //删除
            break;
        }
      }
    },
    keepDetailDialog(rowData) {
      //查看
      if (rowData.sendMessage == "未发送短信提醒") {
        //根据提醒状态区分dialog的标题
        this.dialogTitle = "开票失败记录详情";
      } else {
        this.dialogTitle = "短信详情";
      }
      this.dataDialogType = "keepData";
      this.detailDialogShow = true;
      let data = [];
      data.push(rowData);
      this.invoiceFailDetail = data;
    },
    openDetailDialog(rowData) {
      //单个发送短信
      this.dialogTitle = "发送短信";
      this.dataDialogType = "sendData";
      this.detailDialogShow = true;
      let data = [];
      data.push(rowData);
      this.invoiceFailDetail = data;
    },
    batchSendMsg() {
      //批量发送短信
      if (this.parkActivityObj.selectionList.length == 0) {
        this.$message({
          message: "至少选择一条记录",
          type: "info",
        });
        return;
      }
      this.dataDialogType = "sendData";
      this.dialogTitle = "发送短信";
      this.detailDialogShow = true;
      this.invoiceFailDetail = this.parkActivityObj.selectionList;
    },
    detailDialogClose(type) {
      //关闭弹窗刷新列表
      this.detailDialogShow = false;
      if (type == true) {
        this.getTableList();
      }
    },
    dealTime(datas) {
      if (datas.params.createTime && datas.params.createTime.length > 0) {
        this.parkActivityObj.listQuery.startDate = datas.params.createTime[0]
          ? dateFormat(datas.params.createTime[0])
          : null;
        this.parkActivityObj.listQuery.endDate = datas.params.createTime[1]
          ? dateFormat(datas.params.createTime[1])
          : null;
        delete datas.params.createTime;
      }
      return datas;
    },
    getTableList() {
      this.loadingFlag = true;
      const params = { ...this.parkActivityObj.listQuery };
      console.log("params", params);
      findInvoicingFailure(params)
        .then((res) => {
          console.log(res, "res");
          res.rows.forEach((item) => {
            if (item.sendMessage === false) {
              item.flag = true;
            } else {
              item.flag = false;
            }
            item.sendMessage =
              item.sendMessage === true ? "已发送短信提醒" : "未发送短信提醒";
            // item.drawerType = item.drawerType === null || '' ? "税盘" : "全电";
            if (item.drawerType === "JIUGUAN") {
              item.drawerType = "全电-酒馆";
            } else if (item.drawerType === "CARPARK") {
              item.drawerType = "全电-金威";
            } else {
              item.drawerType = "税盘-润欣";
            }
          });
          this.dataset.pageVO.total = res.total;
          this.dataset.tableData = res.rows;
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    dataInit() {
      this.getTableList();
    },
    retryinginvoicingHandler() {
      if (this.parkActivityObj.selectionList.length === 0) return;
      this.$confirm(
        "请确定金蝶发票软件开启后，点击“确定”重试尝试开票",
        "重试开票",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then((success) => {
          const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          let billNo = [];
          this.parkActivityObj.selectionList.map((item) => {
            billNo.push(item.billNo);
          });
          retryingInvoicing({
            billNoListStr: billNo.join(","),
          }).then((res) => {
            if (res.code === 200) {
              this.invoicingResults = res.data;
              this.detailDialogInvoicing = true;
              loading.close();
              this.getTableList();
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    singleDeleteHandler(row) {
      console.log(row, "222");
      this.$confirm("是否确认删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((success) => {
          deleteInvoice({
            billNoListStr: row.billNo,
          }).then((res) => {
            if (res.code === 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getTableList();
            } else {
              this.$message.warning("删除失败！");
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    batchDeleteHandler() {
      if (this.parkActivityObj.selectionList.length === 0) return;
      this.$confirm(
        "确定删除" + this.parkActivityObj.selectionList.length + "条记录吗？",
        "提示",
        {
          confirmButtonText: "删除",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then((success) => {
          let billNo = [];
          this.parkActivityObj.selectionList.map((item) => {
            billNo.push(item.billNo);
          });
          deleteInvoice({
            billNoListStr: billNo.join(","),
          }).then((res) => {
            if (res.code === 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getTableList();
            } else {
              this.$message.warning("删除失败！");
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped lang="less">
.park-activity-comp {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      height: 36px;
    }

    .icon-loudoutu {
      font-size: 12px;
    }

    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }

    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }

    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }

    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }

    .not-allowed-primary-button {
      cursor: not-allowed;
      color: #fff;
      background-color: #a0cfff;
      border-color: #a0cfff;
    }
  }
}
</style>